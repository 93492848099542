// For simplifying window related tasks
// for eg: cache burst on new deployment
import { Workbox } from 'workbox-window'

export default () => {
    if (process.env.NODE_ENV !== 'production') return
    if ('serviceWorker' in navigator) {
        const wb = new Workbox('sw.js');
        wb.addEventListener('installed', event => {
            if (event.isUpdate) {
                if (confirm('New app update is avaialble, Click ok to refresh')) {
                    window.location.reload()
                }
            }
        })
        wb.register();
    }
}