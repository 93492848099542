import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import registerServiceWorker from '@pwa/serviceWorkerRegistration'
import './index.scss'
declare global {
    interface Window {
        __INITIAL__DATA__: any;
    }
}

// Client-side rendering
// ReactDOM.render(<App />, document.getElementById('app'))

// Server-side rendering
const data = window?.__INITIAL__DATA__?.data;
ReactDOM.hydrate(<App />, document.getElementById("app"));

registerServiceWorker()