import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import loadable from '@loadable/component'

const getLazyComponent = (containerName: string) => {
	switch (containerName) {
		case 'HomePage':
			return import(/* webpackChunkName: "home" */ '@pages/Home');
		case 'WebPresensePage':
			return import(/* webpackChunkName: "web-presense" */ '@pages/WebPresense');
		case 'ProjectsPage':
			return import(/* webpackChunkName: "projects" */ '@pages/Projects');
		case 'NotFoundPage':
			return import(/* webpackChunkName: "404" */ '@pages/404');
		default:
			return Promise.reject('No container with specified name found!');
	}
}

const App: React.FC = () => {
	const HomePage = loadable(() => getLazyComponent('HomePage'))
	const WebPresensePage = loadable(() => getLazyComponent('WebPresensePage'))
	const ProjectsPage = loadable(() => getLazyComponent('ProjectsPage'))
	const NotFoundPage = loadable(() => getLazyComponent('NotFoundPage'))

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/web-presense"
					element={<WebPresensePage />}
				/>
				<Route
					path="/projects"
					element={<ProjectsPage />}
				/>
				<Route
					path="/"
					element={<HomePage />}
				/>
				<Route
					path="*"
					element={<NotFoundPage />}
				/>
			</Routes>
		</BrowserRouter>
	)
}

export default App
